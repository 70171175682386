import React from "react";
 

import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "@material-ui/core";

function CommunityPage() {
  const UseStyles = makeStyles(styles);
  const UseImgStyles = makeStyles(imgStyles);

  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {community && <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
            <h2>Wspólnota {community.name}</h2>
            <img src={`/zdjecia/Wspólnoty/${community.name}.jpg`} alt="" style={{ maxWidth: '100%' }} />
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
              <div >
                <img src={`/zdjecia/Księża/${community.priest}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Opiekun: </h3>
                <h3>{community.priest}</h3>
              </div>
              <div >
                <img src={`/zdjecia/Liderzy/${community.lider}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Lider: </h3>
                <h3>{community.lider}</h3>
              </div>
            </div>
            {community.meetings &&
              <>
                <h3 style={{ fontWeight: 'bold' }}>Spotkania: </h3>
                <h3>{community.meetings}</h3>
              </>
            }
            <h3 style={{ fontWeight: 'bold' }}>O wspólnocie: </h3>
            <h3>{community.description}</h3>
            {community.comming && community.comming.map((item, idx) => (
              <h3 key={idx}>{item}</h3>))}
            {community.news &&
              <>
                <h3>Aktualności: </h3>
                {community.news.map((item, idx) => (
                  <Link key={idx} href={`/${item.link}`} id={`#${item.link}`}>
                    <img src={`/${item.posterUrl}`} className={imgClass.img} alt={item.alt}/>
                  </Link>
                )
                )}
              </>
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}

 
  const community =     {
    "url": "emmanuel",
    "name": "EMMANUEL",
    "lider": "Kamila Chmura",
    "priest": "ks. Łukasz Szuster",
    "meetings": "Parafia pw. św. Jana Chrzciciela w Ozimku, wtorek godz. 19:00/20:00",
    "description": "Nasza Wspólnota 'Emmanuel' powstała w 2010 roku z głębokiej potrzeby grupy ludzi przy Ozimeckim kościele. Ludzie ci zapragnęli regularnie spotykać się razem, by uwielbiać Boga i głosić Dobrą Nowinę o Jezusie na różne sposoby: poprzez Eucharystię, adorację, uwielbienie, posługę modlitwą wstawienniczą i głoszenie Bożego Słowa. Przyjęliśmy nazwę „Emmanuel' (Bóg z nami), która do tej pory prowadzi nas do tego, by doświadczać obecności Boga wiernie stawając przed Nim w radości i uwielbieniu, zgodnie z biblijną obietnicą: „Bo gdzie są dwaj albo trzej zebrani w Imię Moje, tam jestem pośród nich' (Mt 18,20). Spotykamy się w każdy wtorek o godz. 19:00 (jesień, zima) lub godz. 20:00 (wiosna, lato) po wieczornej Mszy św. parafialnej. Na spotkaniu wspólnie się modlimy. Raz w miesiącu (pierwszy wtorek miesiąca) przeżywamy wspólnotową Eucharystię połączoną z Wieczorem Uwielbienia."
  }
   

export default CommunityPage;
